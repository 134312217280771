import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-end mb-10" }
const _hoisted_2 = { class: "d-flex flex-column align-items-end" }
const _hoisted_3 = { class: "d-flex align-items-center mb-2" }
const _hoisted_4 = { class: "me-3" }
const _hoisted_5 = { class: "text-muted fs-7 mb-1 me-1" }
const _hoisted_6 = {
  href: "#",
  class: "fs-5 fw-bolder text-gray-900 text-hover-primary ms-1"
}
const _hoisted_7 = { class: "symbol symbol-35px symbol-circle" }
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.time), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.name), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("img", {
            alt: "Pic",
            src: _ctx.image
          }, null, 8, _hoisted_8)
        ])
      ]),
      _createVNode(_component_router_link, {
        to: { name: 'Activity Detail', params: { id: _ctx.actId } },
        class: "p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-end"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.text), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}